import React, { useEffect, useState } from "react";
import ColorButton from "../ColorButton";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Utils from "../../features/Utils";
import { useRecoilValue } from "recoil";
import { UserInfo } from "../../recoil";

const Main = () => {
  const [showComponent, setShowComponent] = useState(true);
  const [passengersCount, setPassengersCount] = useState(0);

  const [itineraryList, setItineraryList] = useState([]);
  const [itineraryProgramList, setItineraryProgramList] = useState([]);

  const vanPriceList = [{ minPax: 1, maxPax: 6, price: 230000 }, { minPax: 7, maxPax: 10, price: 235000 }, { minPax: 11, maxPax: 14, price: 360000 }, { minPax: 15, maxPax: 20, price: 590000 }, { minPax: 21, maxPax: 24, price: 595000 }, { minPax: 25, maxPax: 28, price: 720000 }, { minPax: 29, maxPax: 34, price: 950000 }, { minPax: 35, maxPax: 38, price: 955000 }, { minPax: 39, maxPax: 99, price: 1080000 }];
  const guidePriceList = [{ minPax: 1, maxPax: 4, price: 200000 }, { minPax: 5, maxPax: 10, price: 150000 }, { minPax: 11, maxPax: 14, price: 100000 }, { minPax: 15, maxPax: 99, price: 50000 }];

  function handleChange(event) {
    const value = event.target.value.replace(/\+|-/ig, '');
    if (Number(value) < 100)
      setPassengersCount(Number(value));
  }

  async function fetchData() {
    let res = await Utils.GetFitItineraryInfo();

    if (res !== undefined) {
      setItineraryList(res.fitItinerary);
      setItineraryProgramList(res.fitItineraryProgram);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div className="flex flex-grow flex-row flex-nowrap h-full pt-24">
      <div
        className="flex flex-col w-full"
        style={{
          padding: 20,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {
          showComponent && <div className="flex flex-col w-full h-full justify-items-center items-center">
            <div className="flex flex-col w-1/2 md:w-full h-1/2" style={{ background: "beige", justifyContent: "center", alignItems: "center" }}>
              <div className="font-bold text-2xl mb-2">Van ￦{Utils.formatNumber(vanPriceList.find(item => item.minPax <= passengersCount && item.maxPax >= passengersCount)?.price)} x 1</div>
              <div className="flex flex-row">
                <div className="font-bold mr-2">Passengers</div>
                <span className="mr-2">
                  <button onClick={() => {
                    if (passengersCount - 1 >= 0)
                      setPassengersCount(passengersCount - 1)
                  }}>
                    <FontAwesomeIcon icon={faCircleMinus} className="text-2xl" />
                  </button>
                </span>
                <input className="text-center w-24 mr-2 font-bold"
                  value={passengersCount}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={handleChange}
                />
                <span>
                  <button onClick={() => {
                    if (passengersCount + 1 <= 99)
                      setPassengersCount(passengersCount + 1)
                  }
                  }>
                    <FontAwesomeIcon icon={faCirclePlus} className="text-2xl" />
                  </button>
                </span>
              </div>
            </div>

            <div className="flex flex-col w-1/2 md:w-full h-1/2" style={{ background: "crimson", justifyContent: "center", alignItems: "center", marginBottom: 5 }}>
              <div className="flex flex-row justify-items-center items-center">
                <div className="text-white font-bold text-2xl mb-2 mr-2">Guide</div>
                <div className="text-white font-bold text-2xl mb-2 flex flex-col justify-items-center items-center mr-2">
                  Yes
                  <input
                    id="checkbox_id"
                    type="checkbox"
                  />
                </div>
                <div className="text-white font-bold text-2xl mb-2 flex flex-col justify-items-center items-center">
                  No
                  <input
                    id="checkbox_id"
                    type="checkbox"
                  />
                </div>
              </div>
              <div className="text-white font-bold text-2xl mb-2 mr-2">￦{Utils.formatNumber(guidePriceList.find(item => item.minPax <= passengersCount && item.maxPax >= passengersCount)?.price)}</div>
            </div>
          </div>
        }
        {
          !showComponent && <div className="flex flex-col w-full h-full justify-items-center items-center" style={{ marginBottom: 5 }}>
            <div className="flex flex-col w-1/2 md:w-full h-full p-4" style={{ background: "aquamarine", justifyContent: "center", alignItems: "center", }}>
              <div className="text-white font-bold text-2xl" style={{ marginBottom: 20 }}>Itinerary</div>
              <div className="grid grid-cols-2 gap-4">
                {
                  itineraryList.map(item => {
                    return (
                      <div
                        key={item.id}
                        className="bg-sky-500/50 hover:bg-sky-700 text-white font-bold text-center p-2 rounded-md"
                      >
                        <div className="mb-2" title="location">
                          {item.location}
                        </div>
                        {
                          // 일정 목록
                          itineraryProgramList.filter(program => program.itineraryId === item.id).map(program => {
                            return (
                              <div key={program.id} className="font-normal text-sm">
                                <div className="font-bold">DAY {program.date}</div>
                                <div className="mb-2">{program.content}</div>
                              </div>
                            )
                          })
                        }
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
        }
        <div className="flex flex-col w-1/2 md:w-full" style={{ justifyContent: "center", alignItems: "center" }}>
          <ColorButton title="Select Itinerary" width="100%" color="slateblue" handler={async () => {
            setShowComponent(!showComponent);
          }} />
        </div>
      </div>
    </div>
  );
};

export default Main;
