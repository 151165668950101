import React from "react";
import TopBar from "./TopBar";

import BodyBox from "./BodyBox";
import * as Utils from "../../features/Utils"

export default function Root() {
  Utils.useAxiosInterceptor();

  return (
    <div className="flex flex-col min-h-screen">
      <TopBar />
      <BodyBox />
    </div>
  )
}
