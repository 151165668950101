import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "./components/Main";
import NotFoundPage from "./components/NotFound";
import ShopRoot from "./components/shop/Root";
import MedicalRoot from "./components/medical/Root";
import ReservationRoot from "./components/reservation/Root";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <RecoilRoot>
      <div>
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/" element={<Main />} />
          <Route path="/shop/*" element={<ShopRoot />} />
          <Route path="/medical/*" element={<MedicalRoot />} />
          <Route path="/reservation/*" element={<ReservationRoot />} />
        </Routes>
      </div>
    </RecoilRoot>
  );
}

export default App;
