import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Utils from "../../features/Utils";

const ProductDetail = () => {
  const params = useParams();
  const [data, setData] = useState();
  const [imgs, setImgs] = useState();
  const [tagPrice, setTagPrice] = useState("");

  //콤마 찍어주기 함수
  // const commaSetter = (price) => {
  //   if (!price) {
  //     return;
  //   }

  //   const result = price
  //     .toString()
  //     .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  //   setTagPrice(result);
  // };

  const getProductDetail = async () => {
    const result = await Utils.ProductDetail({ id: params.id });
    if (!result.result) {
      alert("상품 불러오기에 문제가 있습니다. 관리자에게 문의하세요.");
      return;
    }

    setTagPrice(Utils.formatNumber(result.item[0].price));

    setData(result.item[0]);
    setImgs(result.imgList);
  };

  useEffect(() => {
    getProductDetail();
  }, []);

  return (
    <div className="mt-24 m-4 w-full flex flex-col items-center">
      {data && (
        <div className="text-2xl pt-3 text-gray-400">
          <div>
            Name : <span className="text-4xl text-black">{data.name}</span>
          </div>
          <div className="mt-4">
            Price : <span className="text-4xl text-black">₩{tagPrice}</span>
          </div>
        </div>
      )}
      <div className="flex flex-col items-center">
        {imgs?.map((img, index) => {
          return (
            <div key={index}>
              <img src={img.fileUrl} alt="이미지를 찾을 수 없습니다." />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductDetail;
