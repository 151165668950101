import React from "react";
import { useNavigate } from "react-router";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span className="text-xl mb-5">Page Not Found</span>
      <button
        className="border-2 p-2 rounded-lg bg-red-300"
        onClick={() => {
          navigate(-1);
        }}
      >
        Return To Back
      </button>
    </div>
  );
};

export default NotFound;
