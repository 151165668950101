import React, { useEffect, useState } from "react";
import BestTour from "../assets/imgs/partners/besttour.png";
import ModernSkyTour from "../assets/imgs/partners/modernskytour.png";
import UHX from "../assets/imgs/partners/uhx.png";
import ABOEX from "../assets/imgs/partners/aboex.png";
import "../components/TextContents.css"

const partnersObj = [
  { title: "c_8.png", name: "여행사_Indotravelstore", category: "agency", url: "https://www.indotravelstore.com/" },
  { title: "c_9.png", name: "여행사_sulitDreamDestinations", category: "agency", url: "https://sulitdreamdestinations.com.ph/" },
  { title: "manejkom.png", name: "여행사_manejkom", category: "agency", url: "https://manejkomtravel.com/" },
  { title: "cicerone.png", name: "여행사_ciceroneTravel", category: "agency", url: "https://www.cicerone-travel.net/" },
  { title: "jm.png", name: "여행사_JMAccess", category: "agency", url: "https://jmtravelph.com/" },
  { title: "redpine.png", name: "여행사_RedPines", category: "agency", url: "http://www.redpinestravel.com/" },
  { title: UHX, name: "여행사_UHX", category: "agency", url: "https://www.uhxtravelcentral.com/", isLocal: true },
  { title: ABOEX, name: "여행사_UHX", category: "agency", url: "https://www.uhxtravelcentral.com/", isLocal: true },
  { title: "a_1.webp", name: "항공_cathayPacific" },
  { title: "a_2.jpg", name: "항공_philippineAirlines" },
  { title: "a_3.png", name: "항공_cebuPacific" },
  { title: "a_4.png", name: "항공_가루다인도네시아" },
  { title: "c_12.png", name: "은행_BDO" },
  { title: "b_2.png", name: "은행_Maybank" },
  { title: "h_1.png", name: "호텔_뉴서울" },
  { title: "h_2.jpg", name: "호텔_스카이파크" },
  { title: "h_3.jpg", name: "호텔_스탠포드" },
  { title: "h_4.png", name: "호텔_프린스서울" },
  { title: "shilla.png", name: "면세점_신라", category: "dutyfree", url: "https://www.shilladfs.com/" },
  { title: "shinsegae.png", name: "면세점_신세계", category: "dutyfree", url: "https://www.shinsegaedf.com/" },
  { title: BestTour, name: "베스트투어", category: "bus", url: "" },
  { title: ModernSkyTour, name: "모던스카이", category: "bus", url: "" },
];

const Partners = () => {
  const [position, setPosition] = useState(0);
  const [translateY, setTranslateY] = useState(300);

  function onScroll() {
    setPosition(window.scrollY);
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      if (position >= 900 && translateY >= 0) {
        setTranslateY(moveY => moveY - 1.0);
      }
    }, 0.2);
    return () => clearInterval(id);
  }, [position, translateY]);

  const partnersUrl = "https://ktravelstore.com:9786/images/kts/partners/";

  return (
    <div className="relative flex flex-col gap-5" style={{ transform: `translateY(${translateY}px)` }}>
      <div className="flex md:flex-row md:gap-5 bg-[#f3f3f3] h-[180px] rounded-[24px] p-[62px] md:p-[20px] text-[#000] items-center justify-between">
        <div className="text-[25px] md:text-sm font-bold">AGENCY</div>
        <div className="flex flex-row gap-5 items-center scrollRemoveBox">
          {
            partnersObj.filter(item => item.category === "agency").map((item, index) => {
              return (
                <div key={index} className="flex-none">
                  <a target="_blank" href={`${item.url}`} >
                    <img
                      className="h-[100px] w-[100px]"
                      src={`${item.isLocal === true ? item.title : partnersUrl + item.title}`}
                      alt={item.title}
                      loading="lazy"
                    /></a>
                </div>
              );
            })
          }
        </div>
      </div>
      <div className="flex bg-[#f3f3f3] h-[180px] rounded-[24px] p-[62px] md:p-[20px] text-[#000] items-center justify-between">
        <div className="text-[25px] md:text-sm font-bold">BUS</div>
        <div className="flex flex-row gap-5 items-center scrollRemoveBox">
          {
            partnersObj.filter(item => item.category === "bus").map((item, index) => {
              return (
                <div key={index}>
                  <a target="_blank" href={`${item.url}`} >
                    <img
                      className="h-[32px]"
                      src={`${item.title}`}
                      alt={item.title}
                      loading="lazy"
                    /></a>
                </div>
              );
            })
          }
        </div>
      </div>
      <div className="flex bg-[#f3f3f3] h-[180px] rounded-[24px] p-[62px] md:p-[20px] text-[#000] items-center justify-between">
        <div className="text-[25px] md:text-sm font-bold">DUTY FREE SHOP</div>
        <div className="flex flex-row gap-5 items-center scrollRemoveBox">
          {
            partnersObj.filter(item => item.category === "dutyfree").map((item, index) => {
              return (
                <div key={index}>
                  <a target="_blank" href={`${item.url}`} >
                    <img
                      className="h-[50px]"
                      src={`${partnersUrl + item.title}`}
                      alt={item.title}
                      loading="lazy"
                    /></a>
                </div>
              );
            })
          }
        </div>
      </div>
    </div>

  );
};

export default Partners;
