export const ROLES = {
  USER: 1,
  GUIDE: 2,
  ADMIN: 3,
  LEADERGUIDE: 4,
  TOURIST: 5,
};


export const ERRORCODE = {
  NEED_TO_LOGIN: {
    CODE: 1000,
    MESSAGE: "",
  },
};