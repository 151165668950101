import React, { useRef, useState } from 'react';
import useDetectClose from '../hooks/useDetectClose'

const Dropdown = props => {
  const dropDownRef = useRef(null);
  const [isOpen, SetIsOpen] = useDetectClose(dropDownRef, false);
  const [selectedItem, SetSelectedItem] = useState("");

  return (
    <div style={{ width: '100%' }}>
      <div style={{ position: 'relative', width: "100%", background: '#fff', display: 'flex', padding: 10, borderRadius: 5, borderWidth: 1, }}>
        <button ref={dropDownRef} onClick={() => { SetIsOpen(!isOpen); }} style={{ outline: 'none', flex: 1, textAlign: 'left', color: selectedItem !== "" ? '#000' : '#9ca3af' }}>
          {selectedItem !== "" ? selectedItem : props.title}
        </button>
        <ul style={{
          background: '#000000cc',
          borderRadius: 0,
          position: 'absolute',
          top: 50,
          left: 0,
          width: 205,
          padding: 10,
          zIndex: 1,
          visibility: !isOpen ? 'hidden' : 'visible',
        }}>
          {
            props.data?.map((item, index) => {
              return <li
                key={item + index}
                style={{ color: '#fff', cursor: 'pointer', padding: 2 }}
                onClick={(e) => { SetIsOpen(!isOpen); SetSelectedItem(e.target.textContent); props.setData(e.target.textContent) }}>
                {item}
              </li>;
            })
          }
        </ul>
      </div>
    </div>
  )
};

export default Dropdown;