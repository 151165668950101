import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ColorButton from "../ColorButton";
import * as Utils from "../../features/Utils";
import { UserInfo } from "../../recoil";
import { useRecoilState, useSetRecoilState } from "recoil";

export default function Login() {
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [userPw, setUserPw] = useState("");

  const [myInfo, setMyInfo] = useRecoilState(UserInfo);

  const [autoLogin, setAutoLogin] = useState(false);

  //체크 박스 체인지 함수
  const checkBoxSwitch = () => {
    setAutoLogin(!autoLogin);
  };

  async function handleSignIn() {
    var userInfo = await Utils.SignIn({
      account: userId,
      password: userPw,
      autoLogin,
    });

    setMyInfo(userInfo);

    if (userInfo !== undefined) {
      navigate("/shop/main");
    } else {
      await Utils.Logout();
      setMyInfo({});
      alert("로그인 실패");
    }
  }

  const onEnterPress = (e) => {
    if (e.key === "Enter") {
      handleSignIn();
    }
  };

  useEffect(() => {
    if (myInfo?.account !== undefined) {
      navigate("/shop/main");
    }
  }, [myInfo, navigate]);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              textAlign: "center",
              marginBottom: 10,
              fontWeight: "bold",
            }}
          >
            Entrance
          </div>
          <span>
            <input
              className="border-2 rounded-lg"
              placeholder="ID"
              type="text"
              name="userId"
              style={{
                textAlign: "center",
                marginBottom: 10,
                width: 200,
                paddingTop: 7,
                paddingBottom: 7,
              }}
              onChange={(e) => {
                setUserId(e.target.value);
              }}
              value={userId}
            />
          </span>
          <span>
            <input
              className="border-2 rounded-lg"
              placeholder="Password"
              type="password"
              name="userPw"
              style={{
                textAlign: "center",
                marginBottom: 10,
                width: 200,
                paddingTop: 7,
                paddingBottom: 7,
              }}
              onChange={(e) => {
                setUserPw(e.target.value);
              }}
              value={userPw}
              onKeyDown={onEnterPress}
            />
          </span>
          <span>
            <ColorButton
              title="Confirm"
              color="slateblue"
              width={200}
              handler={() => {
                handleSignIn();
              }}
            />
          </span>
          <div className="pt-4 pb-2 flex items-center justify-center border-t border-solid border-slate-200">
            <input
              className="w-6"
              id="auto-login"
              type="checkbox"
              onChange={checkBoxSwitch}
            />
            <label htmlFor="auto-login">Auto Login</label>
          </div>
        </div>
      }
    </div>
  );
}
