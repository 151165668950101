import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import * as Utils from "../../features/Utils";
import { UserInfo } from "../../recoil";

const AddNewProduct = ({ item }) => {
  const userInfo = useRecoilValue(UserInfo);

  const cssSpan =
    "md:w-full w-1/2 text-lg py-4 flex justify-center item-center";
  const cssLabel = "w-20";
  const cssInput = "border-2 rounded-lg h-10 w-full";

  const [productName, setProductName] = useState("");
  const [price, setPrice] = useState("");
  const [imgFile, setImgFile] = useState([]);
  const [productData, setProductData] = useState({
    accessToken: userInfo.accessToken,
  });

  // file input 에서 파일 선택
  function setFile(event) {
    // console.log(event.target.files);
    setImgFile(event.target.files);
  }

  const clickedSubmit = async () => {
    let res = await Utils.AddNewProduct({ productData });
    if (res?.result) {
      alert("등록완료");
      setProductName("");
      setPrice("");
      setImgFile([]);
    } else {
      alert("문제가 발생하였습니다. 관리자에게 문의해주세요.");
    }
  };

  useEffect(() => {
    setProductData({ ...productData, productName, price, imgFile });
  }, [productName, price, imgFile]);

  return (
    <div className="mt-28 m-4 w-full ml-8">
      <div className="text-xl font-bold mb-4">상품 등록</div>
      <div className="flex flex-col">
        <span className={cssSpan}>
          <label className={cssLabel}>상품명</label>
          <span className="md:w-full w-80">
            <input
              className={cssInput}
              type="text"
              onChange={(e) => {
                setProductName(e.target.value);
              }}
              value={productName}
            />
          </span>
        </span>
        <span className={cssSpan}>
          <label className={cssLabel}>가격</label>
          <span className="md:w-full w-80">
            <input
              className={cssInput}
              type="text"
              onChange={(e) => {
                setPrice(e.target.value);
              }}
              value={price}
            />
          </span>
        </span>
        <span className={cssSpan}>
          <label className={cssLabel}>파일</label>
          <span className="md:w-full w-80">
            <input
              className={cssInput}
              type="file"
              onChange={setFile.bind(this)}
              multiple
            />
          </span>
        </span>

        <span className={cssSpan}>
          <button
            className="bg-orange-800 rounded-xl text-white py-2 mx-4 w-full"
            onClick={() => {
              clickedSubmit();
            }}
          >
            등록하기
          </button>
        </span>
      </div>
    </div>
  );
};

export default AddNewProduct;
