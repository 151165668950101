import React from "react";
import VideoSrc from "../assets/video/intro_video.mp4";

const VideoBox = () => {
  return (
    <video
      className="ktsVideo rounded-br-3xl rounded-t-3xl md:rounded-none"
      width="100%"
      autoPlay="autoplay"
      loop
      muted="muted"
    >
      <source src={VideoSrc} type="video/mp4" />
    </video>
  );
};

export default VideoBox;
