import React from "react";
import Topbar from "../Topbar";
import Main from "./Main";

const Root = () => {
  return (
    <div>
      <Topbar />
      <Main />
    </div>
  );
};

export default Root;
