import React, { useEffect, useState } from "react";
import "./TextContents.css";
import VideoBox from "./VideoBox";
import Partners from "./Partners";
import Gallery from "./Gallery";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import INSTARGRAM from "../assets/imgs/icn-instagram.svg";
import FACEBOOK from "../assets/imgs/icn-facebook.svg";
import StoryItem from "./StoryItem";
import useInterval from "../hooks/useInterval";

const TextContent = ({
  sectionMovie,
  sectionService,
  sectionStory,
  sectionOurTeam,
  sectionVision,
  sectionPartners,
  sectionGallery,
  sectionCompanyInfo,
}) => {

  var vid = document.getElementsByClassName("ktsVideo");
  const [isMuted, setIsMuted] = useState(true);

  function enableMute() {
    let video = vid[0];
    setIsMuted(!isMuted);
    video.muted = !video.muted;
  }

  const [position, setPosition] = useState(0);
  const [translateX, setTranslateX] = useState(-3244);
  const [visionTranslateY, setVisionTranslateY] = useState(200);

  // 스크롤시 버벅이는 느낌
  // useInterval(() => {
  //   if (translateX <= -3244)
  //     setTranslateX(-192)
  //   else
  //     setTranslateX(translateX - 0.2);
  // }, 0.2);

  useEffect(() => {
    const id = setInterval(() => {
      if (position >= 2800 && visionTranslateY >= 0) {
        setVisionTranslateY(moveY => moveY - 1.0);
      }
    }, 0.2);
    return () => clearInterval(id);
  }, [position, visionTranslateY]);

  function onScroll() {
    setPosition(window.scrollY);
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div className="text__container">
      {/* <div className="fixed top-14 z-50">{position} // {visionTranslateY}</div> */}
      <div className="about pt-[43pt] px-[10vw] md:px-0" ref={sectionMovie}>
        <div className="flex relative mb-[200px]" style={{ opacity: (900 - position) / 900 }}>
          <VideoBox />
          <div className="flex flex-col absolute z-[10] text-[white] font-normal left-20 top-20 md:left-5 md:top-5 text-[32px] text-start">
            <div className="md:text-sm md:font-bold">Every moment in tour</div>
            <div className="md:text-sm md:font-bold">Made with KTS</div>
          </div>
          <div className="absolute flex flex-row z-[20] bottom-4 left-5 gap-3 items-center">
            <div onClick={() => enableMute()} className="flex bg-[black] rounded-full p-2 cursor-pointer hover:bg-[#afafaf]">
              {isMuted === true ? <VolumeOffIcon style={{ color: "#ffffff" }} /> : <VolumeUpIcon style={{ color: "#ffffff" }} />}
            </div>
            <div className="text-[23px] md:text-sm md:font-bold text-[black]">SOUND</div>
          </div>
          <svg className="flex absolute left-[-1px] bottom-[-31px] z-[10] md:hidden">
            <path d="M 0 0 Q 0 40 40 40 L 150 40 Q 190 40 190 80 Q 190 120 230 120 L 0 120 L 0 0" fill="#ffffff" />
          </svg>
        </div>
      </div>
      <div className="px-[10vw] pt-[5vw] pb-[10vw] md:px-[10px] overflow-hidden" ref={sectionService}>
        <h1 className="font-bold mb-5 text-start text-[30px] text-[black]">KTS creates a better tour</h1>
        <div className="flex flex-col text-start text-[black] text-[17px] mb-[100px] gap-2">
          <div>We collaborate with partners to identify the travel solutions that make sense for the group.</div>
          <div>A tourist company leading the MICE industry and specializing in inbound tours.</div>
          <div>All of guide have "Tourism Interpretation Guide license" from Korea government.</div>
          <div>They have many experience for several years.</div>
          <div>Tourists can make new friend in Korea.</div>
        </div>
        <h1 className="font text-start text-[25px] text-[black] mb-[30px]">Featured Partners</h1>
        <Partners />
      </div>
      <div className="px-[10vw] pt-[5vw] pb-[10vw] md:px-[10px] " ref={sectionStory}>
        <h1 className="font-bold mb-5 text-start text-[30px] text-[black]">History</h1>
        <StoryItem Year="2017" Month="APRIL" Contents="Established" />
        <StoryItem Year="2021" Month="DECEMBER" Contents="Founded with name KOREATRAVELSTORE" />
        <StoryItem Year="2022" Month="JULY" Contents="Tour start first group after covid-19" />
        <StoryItem Year="2022" Month="DECEMBER" Contents="Acheive 50 groups a month" />
        <StoryItem Year="2022" Month="DECEMBER" Contents="2022 Brand Awards" />
        <StoryItem Year="2023" Month="JULY" Contents="Corporation conversion" />
        <StoryItem Year="2023" Month="DECEMBER" Contents="Acheive 100 groups a month" ShowLine={false} />
      </div>
      <div className="px-[10vw] pt-[5vw] pb-[10vw] md:px-[10px] our__vision text-start" ref={sectionVision}>
        <h1 className="font-bold mb-10 text-start text-[30px] text-[black]">Vision</h1>
        <div className="text-start flex flex-col gap-5" style={{ transform: `translateY(${visionTranslateY}px)`, opacity: (200 - visionTranslateY) / 200 }}>
          <div className="flex flex-row">
            <div className="flex text-[17px] md:text-sm w-[272px] h-[58px] bg-[#89DCFF] justify-center items-center rounded-[29px] font-bold">CREATE</div>
            <div className="relative flex text-[17px] w-full h-[58px] bg-[#89DCFF] justify-center items-center rounded-r-[29px]">
              <div className="absolute flex w-[29px] h-full bg-[white] top-0 left-0 rounded-r-[29px]" />
              <div className="font-bold text-[black] md:text-sm">Unforgettable experiences</div>
            </div>
          </div>
          <div className="flex flex-row text-[white]">
            <div className="flex text-[17px] md:text-sm w-[272px] h-[58px] bg-[#1ED47E] justify-center items-center rounded-[29px] font-bold">LEAD</div>
            <div className="relative flex text-[17px] w-full h-[58px] bg-[#1ED47E] justify-center items-center rounded-r-[29px]">
              <div className="absolute flex w-[29px] h-full bg-[white] top-0 left-0 rounded-r-[29px]" />
              <div className="font-bold md:text-sm">Industry with tourism</div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex text-[17px] md:text-sm w-[272px] h-[58px] bg-[#FAE100] justify-center items-center rounded-[29px] font-bold">PROVIDE</div>
            <div className="relative flex text-[17px] w-full h-[58px] bg-[#FAE100] justify-center items-center rounded-r-[29px]">
              <div className="absolute flex w-[29px] h-full bg-[white] top-0 left-0 rounded-r-[29px]" />
              <div className="font-bold text-[black] md:text-sm md:px-[40px]">Services verified by numerous customers</div>
            </div>
          </div>
          <div className="flex flex-row text-[white]">
            <div className="flex text-[17px] md:text-sm w-[272px] h-[58px] bg-[#F45E7E] justify-center items-center rounded-[29px] font-bold">BECOME</div>
            <div className="relative flex text-[17px] w-full h-[58px] bg-[#F45E7E] justify-center items-center rounded-r-[29px]">
              <div className="absolute flex w-[29px] h-full bg-[white] top-0 left-0 rounded-r-[29px]" />
              <div className="font-bold md:text-sm md:px-[40px]">Tourism company representing Korea, Best partner for your business</div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-[10vw] pt-[5vw] pb-[10vw] md:px-[10px] gallery" ref={sectionGallery}>
        <h1 className="font-bold mb-5 text-start text-[30px] text-[black]">Chronicles</h1>
        <Gallery />
      </div>
      <div className="flex flex-col text-start bg-[#191F28] text-[13px] text-[#8b95a1] p-10 gap-2" ref={sectionCompanyInfo}>
        <div className="text-[#B0B8C1] text-[15px]">KOREATRAVELSTORE Co.,Ltd</div>
        <div>Registration Number : 631-81-03232 | CEO : Taylor Kim</div>
        <div>Fax : +82-2-2216-7347 | Tel : +82-2-828-8600 | Email : <a href="mailto:koreatravelstore@gmail.com">koreatravelstore@gmail.com</a></div>
        <div>Unit 607, 425, Cheonho-daero, Dongdaemun-gu, Seoul, Republic of Korea</div>
        <div className="flex flex-row select-none gap-2">
          <a target="_blank" href="https://www.facebook.com/Koreatravelstore/" ><img src={FACEBOOK} alt="Toss Facebook"></img></a>
          <a target="_blank" href="https://www.instagram.com/koreatravelstore/" ><img src={INSTARGRAM} alt="Toss Instagram"></img></a>
        </div>
      </div>
    </div >
  );
};

export default TextContent;
