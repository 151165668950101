import React, { useRef, useState } from "react";

export default function ColorButton(Props) {
  const [opacity, SetOpacity] = useState(1);

  return (
    <div
      style={{
        height: Props.height,
        width: Props.width,
        background: "#fff",
        borderLeftWidth: 1,
        borderRightWidth: 2,
        borderBottomWidth: 3,
        borderRadius: 5,
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 5,
        paddingLeft: 5,
        backgroundColor: Props.color,
        opacity: opacity,
        color: "#fff",
        cursor: "pointer",
        fontWeight: "bold",
        textAlign: "center",
      }}
      onClick={Props.handler}
      onMouseEnter={() => {
        SetOpacity(0.4);
      }}
      onMouseLeave={() => {
        SetOpacity(1);
      }}
      onMouseUp={() => {
        SetOpacity(1);
      }}
    >
      {Props.title}
    </div>
  );
}
