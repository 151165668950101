import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { AirplaneTicket } from "@mui/icons-material";
import { UserInfo } from "../../recoil";
import { useRecoilState } from "recoil";
import * as Utils from "../../features/Utils";
import { ROLES } from "../../features/Constant";
import ProductsManage from "../admin/ProductsManage";

export default function Topbar() {
  const navigate = useNavigate();

  const [userDropdown, setUserDropdown] = useState(false);
  const [myInfo, setMyInfo] = useRecoilState(UserInfo);

  //로그아웃 처리
  const removeRefreshToken = async () => {
    // TODO 구현 필요
    await Utils.Logout();
    setMyInfo({});
    // replace true시 로그아웃 상태로 뒤로가기 동작 안됨. 로그인시 사용했던 페이지 접근 불가
    navigate("/shop/login", { replace: true });
  };

  const liCommonCSSUserMenu = "py-1";
  const btnCommonCSSUserMenu = "hover:bg-emerald-200 w-full h-full py-2";
  const userLoginMenu = [
    {
      title: "Tour Manage",
      path: "/shop/admin/tours",
      element: undefined,
      clickable: undefined,
      underDivider: false,
      cssClass: btnCommonCSSUserMenu,
      type: ROLES.ADMIN,
    },
    {
      title: "Product Manage",
      path: "/shop/admin/products",
      element: undefined,
      clickable: undefined,
      underDivider: true,
      cssClass: btnCommonCSSUserMenu,
      type: ROLES.ADMIN,
    },
    {
      title: "Order List",
      path: "/shop/order",
      element: undefined,
      clickable: undefined,
      underDivider: false,
      cssClass: btnCommonCSSUserMenu,
    },
    {
      title: "Logout",
      path: "",
      element: undefined,
      clickable: removeRefreshToken,
      underDivider: false,
      cssClass: btnCommonCSSUserMenu,
    },
  ];

  const [listUserMenus, setListUserMenus] = useState([]);

  const doAutoLogin = async () => {
    const userInfo = await Utils.AutoLogin({
      refreshToken: localStorage.getItem("kts_autoLogin"),
    });

    setMyInfo(userInfo);

    if (userInfo !== undefined) {
      navigate("/shop/main");
    } else {
      await Utils.Logout();
      setMyInfo({});
      alert("자동로그인 실패");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("kts_autoLogin") !== null) {
      doAutoLogin();
    }
  }, []);

  useEffect(() => {
    if (myInfo && myInfo.idRoles === ROLES.ADMIN) {
      setListUserMenus(userLoginMenu);
    } else {
      setListUserMenus(
        userLoginMenu.filter((item) => item.type !== ROLES.ADMIN)
      );
    }
  }, [myInfo]);

  return (
    <>
      <div className="w-full fixed top-0 bg-white" style={{ zIndex: 1 }}>
        <div className="flex p-4 border-b-2 border-coolGray-300 shadow-md">
          <div style={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
            <button
              style={{
                display: "flex",
                backgroundColor: "white",
                border: 0,
                marginLeft: 10,
                padding: 10,
              }}
              onClick={async () => {
                // RecoilRoot 위치 주의
                // 페이지가 RecoilRoot 하위 항목이 아니라면
                // 값 변경 후 이동 시 초기화 되지 않을 수 있음
                if (myInfo.id !== undefined) {
                  navigate("/shop/main", { replace: true });
                }
              }}
            >
              <HomeIcon style={{ width: 30, height: 30, color: "gray" }} />
            </button>
            <button
              style={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "white",
                border: 0,
                marginLeft: 10,
                padding: 10,
              }}
              onClick={async () => {
                // RecoilRoot 위치 주의
                // 페이지가 RecoilRoot 하위 항목이 아니라면
                // 값 변경 후 이동 시 초기화 되지 않을 수 있음
                if (myInfo.id !== undefined) {
                  navigate("/", { replace: true });
                }
              }}
            >
              <AirplaneTicket
                style={{ width: 30, height: 30, color: "gray" }}
              />
            </button>
          </div>
          {myInfo !== undefined &&
            (myInfo.nameKor !== undefined ||
              myInfo.passportNum !== undefined) && (
              <div className="flex md:flex-auto w-1/5 justify-end">
                <button
                  className="px-6 md:px-3 mr-6 whitespace-nowrap font-bold bg-transparent border border-black hover:text-white hover:bg-indigo-600 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg md:py-1 py-2 text-center inline-flex items-center"
                  onClick={() => setUserDropdown(!userDropdown)}
                >
                  {myInfo.nameEng || myInfo.passportNum}
                </button>
                <ul
                  className={`transform ${
                    userDropdown ? `scale-y-100` : `scale-y-0`
                  }
                  transition duration-500 ease-in-out origin-top
                  mt-14 mr-6 rounded
                  w-44 bg-white absolute text-black text-center border-2`}
                  onClick={() => setUserDropdown(!userDropdown)}
                >
                  {/* 상단에 uesrLoginMenu 객체 */}
                  {listUserMenus &&
                    listUserMenus.map((item, index) => (
                      <li
                        key={index}
                        className={
                          item.underDivider
                            ? `border-b ${liCommonCSSUserMenu}`
                            : `${liCommonCSSUserMenu}`
                        }
                      >
                        {item.path ? (
                          <Link to={item.path}>
                            <button
                              className={btnCommonCSSUserMenu}
                              onClick={item.clickable}
                            >
                              {item.title}
                            </button>
                          </Link>
                        ) : (
                          <button
                            className={btnCommonCSSUserMenu}
                            onClick={item.clickable}
                          >
                            {item.title}
                          </button>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            )}
        </div>
      </div>
    </>
  );
}
