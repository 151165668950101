import React from "react";
import Topbar from "../Topbar";
import Main from "./Main";

const Root = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <Topbar />
      <Main />
    </div>
  );
};

export default Root;
