import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as Utils from "../../features/Utils";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProductDeleteModal from "./ProductDeleteModal";

const ProductItem = ({ item, idProd }) => {
  const navigate = useNavigate();

  const productDetail = (productId) => {
    navigate(`/shop/admin/products/detail/${productId}`);
  };

  const toggleHiddenAndFlex = () => {
    document.querySelector("#deleteModal").classList.toggle("hidden");
    document.querySelector("#deleteModal").classList.toggle("flex");
  };

  const deleteProductAlert = () => {
    idProd.current = item.id;
    toggleHiddenAndFlex();
  };

  return (
    <div className="min-h-fit flex border-2 shadow-lg mb-2 px-4 md:pr-1 rounded-lg items-center">
      <div className="text-center w-4 mr-4">{item.id}</div>
      <div className="w-full grid grid-cols-5 items-center">
        <span>
          <img
            className="h-20 my-1 mx-auto rounded-xl"
            src={item.thumbnail ? item.thumbnail : item.fileUrl}
            alt="NoImage"
          />
        </span>
        <span className="text-center">{item?.name}</span>
        <span className="text-right whitespace-nowrap md:pr-2">
          {Utils.formatNumber(item.price)}
        </span>
        <span>
          <button
            className="w-12 float-right bg-red-500 py-3 px-2 md:px-1 text-white rounded-xl whitespace-nowrap md:text-sm"
            onClick={deleteProductAlert}
          >
            <FontAwesomeIcon icon={faXmark} className="text-2xl" />
          </button>
        </span>
        <span>
          <button
            className="float-right bg-sky-600 py-3 px-2 md:px-1 text-white rounded-xl whitespace-nowrap md:text-sm"
            onClick={() => productDetail(item.id)}
          >
            Details
          </button>
        </span>
      </div>
    </div>
  );
};

const ProductsManage = () => {
  const navigate = useNavigate();
  const idProd = useRef(null);
  const [trig, setTrig] = useState("");

  const [productList, setProductList] = useState([]);

  // 관리 상품 목록 가져오기
  const getProductsData = async () => {
    const res = await Utils.GetProductList();

    if (res !== undefined) {
      setProductList(res.result);
    }
  };

  // 상품 등록 페이지 이동
  const clickedNewProduct = () => {
    navigate("/shop/admin/products/add");
  };

  useEffect(() => {
    getProductsData();
  }, [trig]);

  return (
    <div className="pt-20 p-4 flex flex-col w-full">
      <div className="mb-2 flex justify-around items-center fixed w-full bg-white border-b-2 border-zinc-800 pt-4 pb-2">
        <div className="text-xl font-bold ml-4">Product List</div>
        <div>
          <button
            className="bg-green-700 text-white p-2 rounded-lg"
            onClick={clickedNewProduct}
          >
            상품 등록
          </button>
        </div>
      </div>
      <div className="w-full mt-20">
        {productList &&
          productList.map((item, index) => (
            <ProductItem item={item} key={index} idProd={idProd} />
          ))}
      </div>
      <ProductDeleteModal idProd={idProd} setTrig={setTrig} />
    </div>
  );
};

export default ProductsManage;
