import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Utils from "../../features/Utils";
import { UserInfo, ProductList, TouristList } from "../../recoil";
import { useRecoilState, useRecoilValue } from "recoil";
import Checkbox from '@mui/material/Checkbox';
import ColorButton from "../ColorButton";
import { ROLES } from "../../features/Constant";

export default function Order(Props) {
  const navigate = useNavigate();

  const myInfo = useRecoilValue(UserInfo);
  const [productList, setProductList] = useRecoilState(ProductList);
  const [touristList, setTouristList] = useRecoilState(TouristList);

  const [orderList, setOrderList] = useState([]);
  const [checkList, setCheckList] = useState([]);

  const checkBoxHandler = (event, idx) => {
    const updatedState = checkList;
    const updatedFormElement = {
      ...updatedState[idx]
    }

    updatedFormElement.checked = event.target.checked;
    updatedState[idx] = updatedFormElement;
    setCheckList([...updatedState]);
  }

  async function fetchData() {
    var res = await Utils.GetOrerList({
      accessToken: myInfo.accessToken,
    });

    var resTouristList = await Utils.GetTouristList({
      accessToken: myInfo.accessToken,
    });

    var resProductList = await Utils.GetProductList();

    if (res !== undefined && res.result !== undefined) {
      setOrderList(res.result);

      // checkList 생성
      var tmpList = [];
      for (let i = 0; i < res.result.length; i++) {
        tmpList.push({
          id: res.result[i].id,
          checked: false
        });
      }

      setCheckList(tmpList);
    }

    if (resTouristList !== undefined) {
      setTouristList(resTouristList.result);
    }

    if (resProductList !== undefined) {
      setProductList(resProductList.result);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div className="flex flex-col w-full" style={{ height: '100%', }}>
      <div
        style={{
          height: "100%",
          display: "flex",
          marginTop: 90,
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
          paddingRight: 10,
          paddingLeft: 10,
        }}
      >
        {
          <div style={{
            background: "#000",
            color: "#fff",
            bottom: 0,
            width: "100%",
            padding: 20,
            textAlign: "center",
            fontWeight: "bold",
            position: "fixed",
          }}>
            Total Price ￦{
              Utils.formatNumber(orderList?.reduce((a, b) => a = a + (productList.find(product => product.id === b.productsId)?.price * b.quantity), 0))
            }
          </div>
        }
        {orderList?.length > 0 && (myInfo.idRoles === ROLES.TOURIST) &&
          <div style={{ width: "100%", }}>
            <ColorButton title="Delete" color="slateblue" handler={async () => {
              var res = await Utils.RevokeOrder({
                accessToken: myInfo.accessToken,
                orderList: checkList.filter(item => item.checked).map(item => { return item.id }),
              })

              if (res !== undefined && res.result) {
                fetchData();
              }
            }} />
          </div>
        }
        <div style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          marginBottom: 75,
        }}>
          <div>
            {
              // 가이드, 관리자 용
              (myInfo.idRoles === ROLES.GUIDE || myInfo.idRoles === ROLES.ADMIN) && touristList?.map((tourist, index) => {
                return (
                  <div key={tourist.id} style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    overflow: "hidden",
                    overflowY: "auto",
                    borderColor: "slateblue",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderRadius: 5,
                    marginBottom: 5,
                    padding: 10,
                    width: "100%"
                  }}>
                    <div title="touristInfo" style={{ display: "flex", width: "100%", marginBottom: 10 }}>
                      <div style={{ fontWeight: "bold" }}>{tourist.name} ({tourist.password}, {tourist.passportNum})</div>
                    </div>
                    <div title="productInfo" style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "flex-start" }}>
                      {
                        orderList?.filter(order => order.touristId === tourist.id).map((order, index) => {
                          return (
                            <div key={order.id} style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              overflow: "hidden",
                              overflowY: "auto",
                              borderRadius: 5,
                              marginBottom: 5,
                              width: "100%"
                            }}>
                              <div title="productInfo"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                  alignItems: "flex-start",
                                }}>
                                {
                                  productList.filter(product => product.id === order.productsId).map(product => {
                                    return <div key={product.id} style={{ display: "flex", width: "100%", flexDirection: "row", }}>
                                      <div style={{ marginRight: 5 }}>{product.name} ￦{Utils.formatNumber(product.price)} * {order.quantity}</div>
                                      <div style={{ color: '#f00' }}>￦{Utils.formatNumber(product.price * order.quantity)}</div>
                                    </div>
                                  })
                                }
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "flex-end", fontWeight: "bold" }}>
                      ￦ {
                        Utils.formatNumber(orderList.filter(order => order.touristId === tourist.id).reduce((a, b) => a = a + (productList.find(product => product.id === b.productsId)?.price * b.quantity), 0))
                      }
                    </div>
                  </div>
                )
              })
            }
            {
              // 관광객용
              (myInfo.idRoles === ROLES.TOURIST) && orderList.map((item, index) => {
                return (
                  <div key={item.id} style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    overflow: "hidden",
                    overflowY: "auto",
                    borderColor: "slateblue",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderRadius: 5,
                    marginBottom: 5,
                    width: "100%"
                  }}>
                    <div title="baseInfo" style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "flex-start" }}>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Checkbox onChange={(e) => checkBoxHandler(e, index)} checked={checkList[index].checked} />
                        <div style={{ fontWeight: "bold" }}>
                          {item.id}
                        </div>
                      </div>
                      {
                        touristList !== undefined && touristList.length > 0 && (
                          touristList.filter(tourist => tourist.id === item.touristId).map(tourist => {
                            return <div key={tourist.id} style={{ display: "flex", flexDirection: "column", marginBottom: 10, paddingLeft: 10 }}>
                              <div style={{ fontWeight: "bold" }}>{tourist.name}</div>
                              <div>Passport : {tourist.passportNum}</div>
                              <div>Birth : {tourist.password}</div>
                            </div>
                          })
                        )
                      }
                    </div>
                    <div title="productInfo"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        alignItems: "flex-start",
                        justifyContent: "",
                        paddingLeft: 10,
                        marginBottom: 10,
                      }}>
                      {
                        productList.filter(product => product.id === item.productsId).map(product => {
                          return <div key={product.id} style={{ display: "flex", width: "100%", flexDirection: "row" }}>
                            <img
                              style={{
                                width: 200,
                                height: 150,
                                marginRight: 10,
                                objectFit: "cover",
                              }}
                              src={product.thumbnail}
                              alt="No Image"
                            />
                            <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
                              <div style={{ marginRight: 10 }}>{product.name}</div>
                              <div style={{ marginRight: 10 }}>￦{Utils.formatNumber(product.price)} * {item.quantity}</div>
                              <div style={{ color: '#f00' }}>Total ￦{Utils.formatNumber(product.price * item.quantity)}</div>
                            </div>
                          </div>
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>

      </div>
    </div >
  );
}