import React from "react";

const Main = () => {
  return (
    <div className="pt-24">
      <div className="bg-gray-200">의료관광 소개 페이지 작성</div>
    </div>
  );
};

export default Main;
