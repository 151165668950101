import axios from "axios";
import { ERRORCODE } from "./Constant";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { UserInfo } from "../recoil";
import { useNavigate } from "react-router-dom";

const PRODUCTION = process.env.NODE_ENV === "development" ? false : true;

const SERVER_URL = PRODUCTION
  ? "https://ktravelstore.com:1226"
  : "http://localhost:1226";

// axios.interceptors.request.use(
//   function (config) {
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

export function formatNumber(value) {
  if (value === undefined || value === null) return "";
  else {
    var result = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return result;
  }
}

const instance = axios.create({
  baseURL: SERVER_URL,
  withCredentials: true,
});

export const useAxiosInterceptor = () => {
  const [myInfo, setMyInfo] = useRecoilState(UserInfo);
  const navigate = useNavigate();
  const [needToLogin, setNeedToLogin] = useState(false);

  const errorHandler = (error) => {
    let msg = error.message;
    switch (error.code) {
      // refresh 토큰까지 만료된 경우 > 재로그인 요청
      case ERRORCODE.NEED_TO_LOGIN.CODE:
        setNeedToLogin(true);
        setMyInfo({});
        break;
    }

    return error;
  };

  const responseHandler = (response) => {
    return response;
  };

  const responseInterceptor = instance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error.response.data)
  );

  useEffect(() => {
    // 로그인 만료 처리
    if (needToLogin) {
      setNeedToLogin(false);
      navigate("/shop/login");
    }

    return () => {
      instance.interceptors.response.eject(responseInterceptor);
    };
  }, [responseInterceptor, needToLogin]);
};

export async function SignIn(Props) {
  return await instance
    .post(
      "/api/auth/signin",
      {
        account: Props.account,
        password: Props.password,
        autoLogin: Props.autoLogin,
      },
      { withCredentials: true }
    )
    .then(function (response) {
      // login response, accessToken, email, id, point, etc..
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.accessToken}`;
      // console.log(axios.defaults.headers.common.Authorization);
      if (Props.autoLogin) {
        localStorage.setItem("kts_autoLogin", response.data.refreshToken);
      }

      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function Logout(Props) {
  return await instance
    .delete("/api/auth/logout")
    .then((res) => {
      localStorage.removeItem("kts_autoLogin");
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function AutoLogin(Props) {
  return await instance
    .post("/api/auth/autologin", {
      refreshToken: Props.refreshToken,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function GetProductList() {
  return await instance
    .get("/products", { withCredentials: true })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function BuyProduct(Props) {
  return await instance
    .post(
      "/products/buy",
      {
        productId: Props.productId,
        count: Props.count,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function GetOrerList(Props) {
  return await instance
    .get("/products/order", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) { });
}

export async function RevokeOrder(Props) {
  return await instance
    .delete("/products/order", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
      data: {
        orderList: Props.orderList,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function GetTouristList(Props) {
  return await instance
    .get("/api/tourist", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

//국가 목록
export async function GetCountryList() {
  return await instance
    .get("/admin/country/list")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function CreateTour(Props) {
  return await instance
    .post(
      "/tours/",
      {
        startDate: Props.startDate,
        endDate: Props.endDate,
        national: Props.national,
        pax: Props.pax,
      },
      {
        headers: {
          Authorization: `Bearer ${Props.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function GetTourList(Props) {
  return await instance
    .get("/tours/list", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function DeleteTour(Props) {
  return await instance
    .delete("/tours/", {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
      data: {
        tourList: Props.tourList,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function AddNewProduct(Props) {
  const formData = new FormData();
  for (let i = 0; i < Props.productData.imgFile.length; i++) {
    formData.append(`image${i}`, Props.productData.imgFile[i]);
  }
  formData.append("productName", Props.productData.productName);
  formData.append("price", Props.productData.price);

  return await instance
    .post("/products", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${Props.productData.accessToken}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function ProductDetail(Props) {
  return await instance
    .get(`/products/detail/${Props.id}`, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

export async function GetTourist(Props) {
  return await instance
    .get(`/tours/tourist/${Props.id}`, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function DeleteProduct(Props) {
  return await instance
    .delete(`/products/${Props.id}`, {
      headers: {
        Authorization: `Bearer ${Props.accessToken}`,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function GetFitItineraryInfo(Props) {
  return await instance
    .get(`/fit/list`, {
      // 구현 필요
      // headers: {
      //   Authorization: `Bearer ${Props.accessToken}`,
      // },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
}