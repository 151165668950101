import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Utils from "../../features/Utils";

const ProductDetail = () => {
  const params = useParams();
  const [data, setData] = useState();
  const [imgs, setImgs] = useState();

  const getProductDetail = async () => {
    const result = await Utils.ProductDetail({ id: params.id });
    if (!result.result) {
      alert("상품 불러오기에 문제가 있습니다. 관리자에게 문의하세요.");
      return;
    }
    setData(result.item[0]);
    setImgs(result.imgList);
  };

  useEffect(() => {
    getProductDetail();
  }, []);

  return (
    <div className="mt-24 m-4 w-full">
      {data && (
        <div>
          <div>관리번호 : {data.id}</div>
          <div>상품명 : {data.name}</div>
          <div>상품가격 : {data.price}</div>
          <div>상품이미지id : {data.idFilesThumbnail}</div>
        </div>
      )}
      <div>
        {imgs?.map((img, index) => {
          return (
            <div key={index}>
              <img src={img.fileUrl} alt="이미지를 찾을 수 없습니다." />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductDetail;
