import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./Login";
import Main from "./Main";
import NotFound from "../NotFound";
import Order from "./Order";
import ProductsManage from "../admin/ProductsManage";
import ProductDetail from "../admin/ProductDetail";
import TourManage from "../admin/TourManage";
import AddNewProduct from "../admin/AddNewProduct";
import ProductDetailForTourists from "./ProductDetail";

const BodyBox = ({ menuToggle, setMenuToggle }) => {
  return (
    <div className="flex flex-grow flex-row flex-nowrap h-full">
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        <Route path="/main" element={<Main />} />
        <Route path="/order" element={<Order />} />
        <Route path="/detail/:id" element={<ProductDetailForTourists />} />
        <Route path="/admin/products" element={<ProductsManage />} />
        <Route path="/admin/products/detail/:id" element={<ProductDetail />} />
        <Route path="/admin/tours" element={<TourManage />} />
        <Route path="/admin/products/add" element={<AddNewProduct />} />
      </Routes>
    </div>
  );
};

export default BodyBox;
