import React, { useState } from "react";
import { Box, Grid, ImageListItem, Modal } from "@mui/material";

const galleryImgs = [
  { title: "p_1.png", thumb: "p_1_thumb.png" },
  { title: "p_2.png", thumb: "p_2_thumb.png" },
  { title: "p_3.png", thumb: "p_3_thumb.png" },
  { title: "p_4.png", thumb: "p_4_thumb.png" },
  { title: "p_5.png", thumb: "p_5_thumb.png" },
  { title: "p_6.png", thumb: "p_6_thumb.png" },
  { title: "p_7.png", thumb: "p_7_thumb.png" },
  { title: "p_8.png", thumb: "p_8_thumb.png" },
  { title: "p_9.png", thumb: "p_9_thumb.png" },
  { title: "p_10.png", thumb: "p_10_thumb.png" },
  { title: "p_11.png", thumb: "p_11_thumb.png" },
  { title: "p_12.png", thumb: "p_12_thumb.png" },
  { title: "p_13.png", thumb: "p_13_thumb.png" },
  { title: "p_14.png", thumb: "p_14_thumb.png" },
  { title: "p_15.png", thumb: "p_15_thumb.png" },
  { title: "p_16.png", thumb: "p_16_thumb.png" },
  { title: "p_17.png", thumb: "p_17_thumb.png" },
  { title: "p_18.png", thumb: "p_18_thumb.png" },
  { title: "p_19.png", thumb: "p_19_thumb.png" },
  { title: "p_20.png", thumb: "p_20_thumb.png" },
  { title: "p_21.png", thumb: "p_21_thumb.png" },
  { title: "p_22.png", thumb: "p_22_thumb.png" },
  { title: "p_23.png", thumb: "p_23_thumb.png" },
  { title: "p_24.png", thumb: "p_24_thumb.png" },
  { title: "p_25.png", thumb: "p_25_thumb.png" },
  { title: "p_26.png", thumb: "p_26_thumb.png" },
  { title: "p_27.png", thumb: "p_27_thumb.png" },
];

const style = {
  position: "absolute",
  top: "10%",
  left: 0,
  m: 4,
};

const Gallery = () => {
  const mainUrl = "https://ktravelstore.com:9786/images/kts/tour/";

  const [modalOpen, setModalOpen] = useState(false);
  const [galleryUrl, setGalleryUrl] = useState("");
  const handleOpen = (imgUrl) => {
    const curl = `${mainUrl + imgUrl}`;
    setGalleryUrl(curl);
    setModalOpen(true);
  };
  const handleClose = () => setModalOpen(false);

  return (
    <>
      <Grid container>
        <Grid item>
          {galleryImgs.map((item) => (
            <ImageListItem
              key={item.title}
              sx={{
                width: 120,
                height: 150,
                margin: 1,
              }}
              onClick={() => handleOpen(item.title)}
            >
              <img
                src={`${mainUrl + item.thumb}`}
                alt={item.title}
                loading="lazy"
                style={{ borderRadius: 6, cursor: "pointer" }}
              />
            </ImageListItem>
          ))}
        </Grid>
      </Grid>
      <Modal open={modalOpen} onClose={handleClose}>
        <Box sx={style} className="select-none outline-none">
          <img
            src={galleryUrl}
            alt={galleryUrl}
            style={{ maxWidth: "100%", maxHeight: "70vh", borderRadius: 10 }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default Gallery;
