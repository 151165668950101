import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

const today = new Date();

export const UserInfo = atom({
  key: "UserInfo",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

// 기본값 설정 시 배열인지 일반변수 인지 체크
export const ProductList = atom({
  key: "ProductList",
  default: [],
});

export const TouristList = atom({
  key: "TouristList",
  default: [],
});

export const CalendarYear = atom({
  key: "CalendarYear",
  default: today.getFullYear(),
});

export const CalendarMonth = atom({
  key: "CalendarMonth",
  default: today.getMonth(),
});
