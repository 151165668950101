import React, { useState, useEffect, forwardRef } from "react";
import * as Utils from '../../features/Utils';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale';
import Dropdown from '../Dropdown'
import { TouristList, UserInfo } from "../../recoil";
import { useRecoilValue } from "recoil";
import ColorButton from "../ColorButton";
import Checkbox from '@mui/material/Checkbox';
import { read, utils } from "xlsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";

export default function TourManage() {
  const [startDate, setStartDate] = useState(convertLocalToUTCDate(new Date()));
  const [endDate, setEndDate] = useState(convertLocalToUTCDate(new Date()));

  const [national, setNational] = useState("");
  const [pax, setPax] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [tourList, setTourList] = useState([]);
  const [checkList, setCheckList] = useState([]);

  const myInfo = useRecoilValue(UserInfo);

  const [showInput, setShowInput] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [touristList, setTouristList] = useState([]);

  const setCountryInfo = async () => {
    const result = await Utils.GetCountryList();

    if (result !== undefined && result.data !== undefined)
      setCountryList(result.data.result);
  };

  async function fetchTourData() {
    var res = await Utils.GetTourList({
      accessToken: myInfo.accessToken,
    })

    if (res !== undefined && res.result !== undefined) {
      setTourList(res.result);

      // checkList 생성
      var tmpList = [];
      for (let i = 0; i < res.result.length; i++) {
        tmpList.push({
          id: res.result[i].id,
          checked: false
        });
      }

      setCheckList(tmpList);
    }
  }

  useEffect(() => {
    setCountryInfo();
    fetchTourData();
  }, []);

  function convertLocalToUTCDate(date) {
    if (!date) {
      return date
    }
    date = new Date(date)
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    return date
  }

  function convertUTCToLocalDate(date) {
    if (!date) {
      return date
    }
    date = new Date(date)
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    return date
  }

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className="example-custom-input" onClick={onClick} ref={ref} style={{ width: '100%', borderWidth: 1, padding: 10, borderRadius: 5, width: "100%" }}>
      {value}
    </button>
  ));

  const checkBoxHandler = (event, idx) => {
    const updatedState = checkList;
    const updatedFormElement = {
      ...updatedState[idx]
    }

    updatedFormElement.checked = event.target.checked;
    updatedState[idx] = updatedFormElement;
    setCheckList([...updatedState]);
  }

  const getArrayBuffer = (file) => {
    return new Promise((acc, err) => {
      const reader = new FileReader();
      reader.onload = (event) => { acc(event.target.result) };
      reader.onerror = (err) => { err(err) };
      reader.readAsArrayBuffer(file);
    });
  }

  // file input 에서 파일 선택
  async function setFile(event) {
    const file = event.target.files[0];
    const temp = await getArrayBuffer(file)
    const wb = read(temp, { cellDates: true, });

    const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], { header: 1 });

    console.log(data);
  }

  return (
    <>
      <div
        className="mt-2 md:mt-14"
        style={{
          padding: 20,
          alignItems: "center",
          marginTop: 80,
          width: "100%",
        }}
      >
        <div title="InputArea" style={{ width: '100%', height: '100%', alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}>

          {
            // 입력창 표시 유무 버튼 영역
          }
          <div className="flex w-full items-center justify-center" style={{ marginBottom: 10, }}>
            <button
              onClick={() => {
                setShowInput(!showInput);
              }}
            >
              {showInput ? <FontAwesomeIcon className="text-3xl" icon={faCaretUp} /> :
                <FontAwesomeIcon className="text-3xl" icon={faCaretDown} />}
            </button>
          </div>

          {
            // 입력창 영역
            showInput &&
            <div style={{ width: '100%', alignItems: 'flex-start', display: 'flex', flexDirection: 'column' }}>
              <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
                <div style={{ width: 80, color: '#9ca3af' }}>Start</div>
                <DatePicker onChange={date => setStartDate(convertLocalToUTCDate(date))} selected={convertUTCToLocalDate(startDate)} locale={ko} dateFormat="yyyy/MM/dd" customInput={<ExampleCustomInput />} showPopperArrow={false} />
              </div>
              <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
                <div style={{ width: 80, color: '#9ca3af' }}>End</div>
                <DatePicker onChange={date => setEndDate(convertLocalToUTCDate(date))} selected={convertUTCToLocalDate(endDate)}
                  locale={ko} dateFormat="yyyy/MM/dd" customInput={<ExampleCustomInput />} showPopperArrow={false} />
              </div>

              <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
                <div style={{ width: 80, color: '#9ca3af' }}>Country</div>
                <Dropdown title="list" data={countryList?.map((item) => { return item.nameEng })} setData={setNational} />
              </div>

              <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', }}>
                <div style={{ width: 80, color: '#9ca3af' }}>PAX</div>
                <input
                  className="placeholder:text-slate-400 block bg-white border border-slate-300 rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                  placeholder="ex) 123"
                  type="text"
                  name="pax"
                  style={{ flex: "100%" }}
                  onChange={(e) => {
                    setPax(e.target.value.replace(/[^0-9]/g, ''));
                  }}
                  value={pax}
                />
              </div>

              <div style={{ display: 'flex', width: '100%', flexDirection: "column", marginBottom: 10 }}>
                <ColorButton title="Create" width="100%" color="slateblue" handler={async () => {
                  var res = await Utils.CreateTour({
                    startDate: startDate,
                    endDate: endDate,
                    national: national,
                    pax: pax,
                    accessToken: myInfo.accessToken,
                  })

                  if (res !== undefined && res.data?.result) {
                    fetchTourData();
                  }
                }} />
              </div>

            </div>
          }

          <div className="flex w-full flex-col">
            <div style={{ marginBottom: 10 }}>Tour List</div>
            {
              // 투어 목록
              tourList?.map((tour, index) => {
                return (
                  <div className="flex w-full flex-row" key={tour.id}
                    style={{
                      borderStyle: "solid",
                      borderWidth: 1,
                      borderRadius: 5,
                      borderColor: "slateblue",
                      marginBottom: 5,
                      paddingRight: 10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <div className="flex w-full flex-row" style={{ alignItems: 'center', }}>
                      <Checkbox onChange={(e) => checkBoxHandler(e, index)} checked={checkList[index]?.checked} />
                      <div style={{ marginRight: 10 }}>
                        {tour.id}
                      </div>
                      <div style={{ marginRight: 10 }}>
                        {tour.name}
                      </div>
                      <div style={{ marginRight: 10 }}>
                        {tour.pax}
                      </div>
                      <div style={{ marginRight: 10 }}>
                        {tour.startDate.slice(0, 10)}
                      </div>
                      <div style={{ marginRight: 10 }}>
                        {tour.endDate.slice(0, 10)}
                      </div>
                    </div>
                    <div style={{
                      background: "slateblue",
                      color: "#fff",
                      padding: 4,
                      textAlign: "center",
                      fontWeight: "bold",
                      borderRadius: 5,
                      cursor: "pointer"
                    }} onClick={async () => {
                      var res = await Utils.GetTourist({
                        accessToken: myInfo.accessToken,
                        id: tour.id,
                      })
                      console.log(res.result);

                      if (res !== undefined && res.result !== undefined) {
                        setTouristList(res.result)
                        setShowDialog(true);
                      }
                    }}>
                      Pax</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      {
        tourList?.length > 0 &&
        <div className="flex w-full" style={{ position: "fixed", bottom: 0 }}>
          <ColorButton title="Delete" color="black" width={"100%"} handler={async () => {
            var res = await Utils.DeleteTour({
              accessToken: myInfo.accessToken,
              tourList: checkList.filter(item => item.checked).map(item => { return item.id }),
            })

            if (res !== undefined && res.result) {
              fetchTourData();
            } else {
              alert("Delete failure");
            }
          }} />
        </div>
      }
      {
        showDialog &&
        <div className="flex w-full h-full justify-center items-center" style={{ background: "#0009", position: "absolute" }}>
          <div className="flex w-1/2 h-1/2 flex-col" style={{
            background: '#fff',
            padding: 10,
            borderRadius: 10,
            position: "relative"
          }}>
            <div style={{
              overflow: "hidden",
              overflowY: "auto",
              position: "relative",
              height: "100%",
            }}>
              {
                touristList.length > 0 ? touristList?.map((tourist, index) => {
                  return (
                    <div key={index} className="flex flex-row" style={{ flex: 1, }}>
                      <div style={{ marginRight: 10, fontWeight: "bold" }}>{index + 1}.</div>
                      <div style={{ marginRight: 10, fontWeight: "bold" }}>{tourist.name}</div>
                      <div>({tourist.passportNum}, {tourist.password})</div>
                    </div>
                  );
                }) :
                  <div style={{ display: "flex", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                    <span style={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center", }}>
                      <label for="input-file" style={{
                        padding: 10,
                        background: "#ff6600",
                        borderRadius: 4,
                        color: "#fff",
                        cursor: "pointer",
                        fontWeight: "bold"
                      }}>UPLOAD</label>
                      <input
                        type="file"
                        onChange={setFile.bind(this)}
                        onClick={(e) => { e.target.value = '' }}
                        id="input-file"
                        style={{ display: "none" }}
                      />
                    </span>
                  </div>
              }
            </div>
            <div style={{ position: "relative", bottom: 0 }}>
              <ColorButton title="close" color={"slateblue"} handler={() => setShowDialog(false)} />
            </div>
          </div>
        </div>
      }
    </>
  );
}