import React from "react";
import * as Utils from "../../features/Utils";

const ProductDeleteModal = ({ idProd, setTrig }) => {
  const toggleHiddenAndFlex = () => {
    document.querySelector("#deleteModal").classList.toggle("hidden");
    document.querySelector("#deleteModal").classList.toggle("flex");
  };

  const deleteProduct = async () => {
    const res = await Utils.DeleteProduct({ id: idProd.current });
    if (!res.result) {
      alert("상품 삭제에 실패했습니다. 관리자에게 문의해주세요.");
    } else {
      setTrig(new Date());
      toggleHiddenAndFlex();
    }
  };

  return (
    <div
      id="deleteModal"
      className="z-10 w-11/12 h-1/2 absolute top-10 hidden justify-center items-center"
    >
      <div className="bg-white w-1/2 h-1/3 border-2 rounded-xl z-20 flex flex-col justify-evenly items-center">
        <div>해당상품을 정말로 삭제하시겠습니까?</div>
        <div className="w-4/5 flex justify-between">
          <button
            className="border-2 bg-red-400 text-white rounded-xl px-3 py-2"
            onClick={deleteProduct}
          >
            삭제
          </button>
          <button
            className="border-2 bg-cyan-500 text-white rounded-xl px-3 py-2"
            onClick={toggleHiddenAndFlex}
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductDeleteModal;
