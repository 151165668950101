import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Utils from "../../features/Utils";
import { UserInfo, ProductList } from "../../recoil";
import { useRecoilValue, useRecoilState } from "recoil";
import ColorButton from "../ColorButton";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import AddBoxIcon from "@mui/icons-material/AddBox";

export default function Main() {
  const navigate = useNavigate();

  const myInfo = useRecoilValue(UserInfo);
  const [productList, setProductList] = useRecoilState(ProductList);

  // 상품 구매 개수
  const [countList, setCountList] = useState([]);

  const counterHandler = (idx, value) => {
    const updatedState = countList;
    const updatedFormElement = {
      ...updatedState[idx],
    };

    updatedFormElement.count = updatedFormElement.count + value;

    if (updatedFormElement.count + value < 0) updatedFormElement.count = 0;

    updatedState[idx] = updatedFormElement;

    setCountList([...updatedState]);
    // 아래와 같이 하면 state 값은 변하지만 참조하는 곳의 UI는 업데이트 되지 않음
    // setCountList(updatedState);
  };

  useEffect(() => {
    // (관리자, 가이드), 관광객 구분
    // 관광객은 account가 존재하지 않음
    if (myInfo?.account === undefined && myInfo?.passportNum === undefined) {
      navigate("/shop/login");
      return;
    }

    async function fetchData() {
      var res = await Utils.GetProductList();

      if (res !== undefined) {
        setProductList(res.result);

        var tmpList = [];
        for (let i = 0; i < res.result?.length; i++) {
          tmpList.push({
            id: res.result[i].id,
            count: 0,
          });
        }
        setCountList(tmpList);
      }
    }
    fetchData();
  }, [myInfo, navigate]);

  return (
    <div
      className="flex md:w-full"
      style={{
        padding: 20,
        alignItems: "center",
        justifyContent: "center",
        marginTop: 80,
      }}
    >
      <div className="flex flex-row md:flex-col md:w-full">
        {productList?.map((item, index) => {
          return (
            <div
              className="w-96 md:flex md:w-full"
              key={index}
              style={{
                flexDirection: "column",
                justifyContent: "center",
                marginBottom: 5,
                marginRight: index !== productList?.length - 1 && 20,
                borderColor: "#486BE2",
                borderStyle: "solid",
                borderWidth: 2,
                borderRadius: 5,
              }}
            >
              <div
                title="topArea"
                className="flex flex-row"
                style={{
                  background: "#486BE2",
                  color: "#fff",
                  fontWeight: "bold",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingRight: 10,
                  paddingLeft: 10,
                }}
              >
                <div title="eventPhrase1" style={{ flex: 1 }}>
                  Good Choice!
                </div>
                <div title="eventPhrase2">Buy Now</div>
              </div>
              <div title="middleArea" className="flex flex-row" style={{}}>
                <img
                  style={{
                    width: 200,
                    height: 150,
                    objectFit: "cover",
                  }}
                  className="hover:cursor-pointer"
                  src={item.thumbnail ? item.thumbnail : item.fileUrl}
                  alt="NoImage"
                  onClick={() => {
                    navigate(`/shop/detail/${item.id}`);
                  }}
                />
                <div
                  title="productInfo"
                  className="flex flex-col"
                  style={{
                    flex: 1,
                    color: "#000",
                    fontWeight: "bold",
                    padding: 10,
                  }}
                >
                  <div style={{ flex: 1 }}>{item.name}</div>
                  <div
                    style={{
                      marginBottom: 5,
                      textAlign: "right",
                      color: "#CF3F37",
                    }}
                  >
                    ￦{Utils.formatNumber(item.price)}
                  </div>
                  <div
                    style={{
                      marginBottom: 5,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{ marginRight: 5, color: "#ccc" }}
                      onClick={() => counterHandler(index, -1)}
                    >
                      <IndeterminateCheckBoxIcon />
                    </div>
                    {countList[index]?.count}
                    <div
                      style={{ marginLeft: 5, color: "#ccc" }}
                      onClick={() => counterHandler(index, +1)}
                    >
                      <AddBoxIcon />
                    </div>
                  </div>
                </div>
              </div>
              <ColorButton
                title="Buy"
                color="#CF3F37"
                width={"100%"}
                handler={async () => {
                  if (countList[index]?.count !== 0) {
                    var res = await Utils.BuyProduct({
                      productId: item.id,
                      count: countList[index]?.count,
                      accessToken: myInfo?.accessToken,
                    });

                    if (res?.result) {
                      alert("Purchase Success");
                    } else {
                      alert("Purchase failure");
                    }
                  }
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
