import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import LogoKTS2 from "../assets/imgs/logo_kts2.png";
import {
  IconButton,
  SwipeableDrawer,
  Divider,
  List,
  ListItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ChevronRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const Topbar = ({
  gotoTop,
  gotoServiceSection,
  gotoStorySection,
  gotoVisionSection,
  gotoGallerySection,
}) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [topbarBgToggle, setTopbarBgToggle] = useState(false);

  const localCss = {
    fontSize: 15,
    fontColor: "#4E5968",
    letSpacing: -0.1,
  };

  const navigationLinks = [
    { name: "INFO", onclick: gotoServiceSection, type: "info" },
    { name: "STORY", onclick: gotoStorySection, type: "story" },
    { name: "VISION", onclick: gotoVisionSection, type: "vision" },
    { name: "NEWS", onclick: gotoGallerySection, type: "news" },
  ];

  const gotoIndex = () => {
    navigate("/");
  };

  const topSubMenusToggle = (e) => {
    const thisTag = e.target.nextSibling;
    thisTag.classList.toggle("hidden");
    thisTag.classList.toggle("flex");
  };

  const subMenusToggle = (e) => {
    const thisTag = e.target.parentNode;
    thisTag.classList.toggle("hidden");
    thisTag.classList.toggle("flex");
  };

  const curScreenLocation = () => {
    if (window.innerWidth > 767) {
      window.scrollY >= 100 ? setTopbarBgToggle(true) : setTopbarBgToggle(false);
    } else {
      window.scrollY > 199 ? setTopbarBgToggle(true) : setTopbarBgToggle(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", curScreenLocation, { capture: true });
    return () => {
      window.removeEventListener("scroll", curScreenLocation);
    };
  }, []);

  return (
    <div
      className={`fixed bg-[white] w-full z-[200] ${topbarBgToggle && "border-b-[1px]"}`}>
      <div className="flex justify-between px-[10vw] md:px-[10px] md:justify-between">
        <div className="flex select-none">
          <button onClick={gotoTop ? gotoTop : gotoIndex}>
            <div className="flex flex-row items-center">
              <img
                src={LogoKTS2}
                alt="logo"
                variant="h6"
                className="h-10 my-2"
              />
              <div className="text-center text-[#202632] font-bold">KOREATRAVELSTORE</div>
            </div>
          </button>
        </div>

        <div className="flex">
          <div className="md:hidden flex text-bold">
            {navigationLinks.map((item, idx) => (
              <React.Fragment key={idx}>
                <Button
                  variant="text"
                  color="inherit"
                  style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    textTransform: "none",
                    fontWeight: localCss.fontWeight,
                    fontSize: localCss.fontSize,
                    color: localCss.fontColor,
                    letterSpacing: localCss.letSpacing,
                  }}
                  onClick={(event) => {
                    if (!item.onclick) {

                    } else {
                      item.onclick();
                    }

                    if (item.subMenus) {
                      topSubMenusToggle(event);
                    }
                  }}
                >
                  {item.name}
                </Button>
                {item.subMenus ? (
                  <div className="bg-white py-1 border-y-2 hidden absolute w-full top-20 left-0">
                    {item.subMenus.map((jtem, jdx) => {
                      return (
                        <button
                          key={jdx}
                          className="hover:bg-zinc-100 hover:opacity-80 hover:text-gray-500 font-semibold text-gray-500 px-1 flex w-full justify-center py-2 rounded-lg"
                          onClick={(event) => {
                            subMenusToggle(event);
                            jtem.onclick();
                          }}
                        >
                          {jtem.name}
                        </button>
                      );
                    })}
                  </div>
                ) : (
                  ""
                )}
              </React.Fragment>
            ))}
          </div>
          <div className="hidden md:flex">
            <button
              className="flex text-coolGray-500 items-center"
              onClick={() => {
                setMenuOpen(true);
              }}
            >
              <MenuIcon style={{ width: 35, height: 35 }} />
            </button>
          </div>
        </div>
      </div>
      {/* 모바일 화면에서 메뉴 */}
      <SwipeableDrawer
        anchor="right"
        open={menuOpen}
        onOpen={() => setMenuOpen(true)}
        onClose={() => {
          setMenuOpen(false);
        }}
      >
        <IconButton
          onClick={() => {
            setMenuOpen(false);
          }}
        >
          <ChevronRight />
        </IconButton>
        <Divider />
        <List>
          {navigationLinks.map((item, idx) => (
            <ListItem key={idx} className="flex flex-col">
              <Button
                key={idx}
                variant="text"
                color="inherit"
                style={{
                  textTransform: "none",
                  fontWeight: localCss.fontWeight,
                  fontSize: localCss.fontSize,
                  color: localCss.fontColor,
                  fontFamily: localCss.fontFamily,
                  marginBottom: 6,
                }}
                onClick={(event) => {
                  if (!item.onclick) {

                  } else {
                    item.onclick();
                  }
                }}
              >
                {item.name}
              </Button>
              {item.subMenus ? (
                <div className="w-full border-t-2 border-b-2 py-1">
                  {item.subMenus.map((jtem, jdx) => {
                    return (
                      <button
                        key={jdx}
                        className="hover:bg-zinc-100 hover:opacity-80 hover:text-gray-500 font-semibold text-gray-500 px-1 flex w-full justify-center py-2 rounded-lg"
                        onClick={(event) => {
                          jtem.onclick();
                          setMenuOpen(false);
                        }}
                      >
                        {jtem.name}
                      </button>
                    );
                  })}
                </div>
              ) : (
                ""
              )}
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
    </div>
  );
};

export default Topbar;
